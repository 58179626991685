import * as React from "react";
import Layout from "../components/Layout";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import { Link } from "gatsby";

// markup
const ThankYouForDL = () => {
  const props = {
    seoTitle: "Managed Services",
    seoDescription:
      "Accelerate your business with Strategix Technology Solutions.",
  };

  return (
    <Layout title={props.seoTitle} description={props.seoDescription}>
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="contact">
            <div className="flex-col-c">
              <h1>Thank You</h1>
              <p>Please click the link below to access your download.</p>
              <a
                href="/media/Ransomware_Readiness_Checklist.pdf"
                target="_blank"
              >
                <button>
                  Download Ransomware Readiness Checklist <span>&#x279C;</span>
                </button>
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ThankYouForDL;
